import { useEffect } from "react";
import { Navigate } from "react-router-dom";

const RedirectOutside = ({ to }) => {
  useEffect(() => {
    if (to) {
      window.location.assign(to);
    } else {
      <Navigate to="/" />
    }
  }, []);
};

export default RedirectOutside;
