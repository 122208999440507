import { useState, useEffect } from 'react';
import throttle from 'lodash-es/throttle';

export function useMousePosition({ disabled = false } = {}) {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });

  useEffect(() => {
    const updateMousePosition = throttle((evt) => {
      setMousePosition({ x: evt.clientX, y: evt.clientY });
    }, 20);

    if (!disabled) {
      window.addEventListener('mousemove', updateMousePosition, false);
    } else {
      window.removeEventListener('mousemove', updateMousePosition, false);
    }

    return () => {
      window.removeEventListener('mousemove', updateMousePosition, false);
    };
  }, [disabled]);

  return mousePosition;
}
