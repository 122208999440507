import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useBackendErrors } from '@/shared/hooks/use-backend-errors';
import {
  createPortalSession as apiCreatePortalSession,
  addActivationCode as apiAddActivationCode,
  propagateSubscription as apiPropagateSubscription,
  modifySubscription as apiModifySubscription,
  activateSubscription as apiActivateSubscription,
} from '../api';
import * as actionCreators from '@/store/actions';

export function useUpdatePlan() {
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(false);
  const [newQuantity, setNewQuantity] = useState(null);
  const [newPrice, setNewPrice] = useState(null);
  const [newPlanPeriodEnd, setNewPlanPeriodEnd] = useState(null);

  const { errors, getErrors } = useBackendErrors();

  async function createPortalSession(stripe_customer_id) {
    try {
      setIsFetching(true);
      const res = await apiCreatePortalSession({ stripe_customer_id });
      window.location.href = res.data;
    } catch (err) {
      console.error(err);
      getErrors(err);
    } finally {
      setIsFetching(false);
    }
  }

  async function addActivationCode({ organization, code }) {
    try {
      setIsFetching(true);
      const res = await apiAddActivationCode({ code, organization });
      return res;
    } catch (err) {
      getErrors(err);
      console.error(err);
      throw err;
    } finally {
      setIsFetching(false);
    }
  }

  async function propagateSubscription(subscriptionData) {
    try {
      setIsFetching(true);
      const res = await apiPropagateSubscription(subscriptionData);
      const quantityUpdateData = res.data;
      setNewPrice(quantityUpdateData?.amount / 100);
      setNewQuantity(quantityUpdateData?.quantity);
      setNewPlanPeriodEnd(quantityUpdateData?.plan_period_end);
    } catch (err) {
      getErrors(err);
      console.error(err);
      throw err;
    } finally {
      setIsFetching(false);
    }
  }

  async function modifySubscription({ subscriptionData, currentOrganization }) {
    try {
      setIsFetching(true);
      const res = await apiModifySubscription(subscriptionData);
      dispatch(
        actionCreators.updateOrganization({
          data: { ...currentOrganization, plan_licenses: res.data.quantity },
        })
      );
    } catch (err) {
      getErrors(err);
      console.error(err);
      throw err;
    } finally {
      setIsFetching(false);
    }
  }

  async function activateSubscription(subscriptionData) {
    try {
      setIsFetching(true);
      const res = await apiActivateSubscription(subscriptionData);
      window.location.href = `${res.data}`;
    } catch (err) {
      getErrors(err);
      console.error(err);
    } finally {
      setIsFetching(false);
    }
  }

  return {
    isFetching,
    errors,
    newPrice,
    newQuantity,
    newPlanPeriodEnd,
    createPortalSession,
    addActivationCode,
    propagateSubscription,
    modifySubscription,
    activateSubscription,
  };
}
