export const PLANS_BENEFITS_LIST = ['data_storage', 'connected_devices', 'data_export', 'api'];

export const PLANS_ADDITIONAL_BENEFITS_LIST = [
  'feature_requests',
  'customer_support',
  'prepayments',
];

export const PLAN_NAMES = {
  free: 'free',
  pro: 'pro',
  enterprise: 'enterprise',
};
