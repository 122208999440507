import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getBackendErrors } from '@/shared/helpers';
import { AuthClass } from '@/util/auth'

export function useBackendErrors(translations = ['errors']) {
  const [errors, setErrors] = useState(null);

  const { t } = useTranslation(translations);

  const getErrors = useCallback((err) => {
    if (!err?.response) {
      setErrors({common: t('errors:unknownError')});
      return;
    }
    const errorsRaw = getBackendErrors(err);
    const {status} = err?.response
    if (status === 401) {AuthClass.signOut()}

    setErrors({
      ...errorsRaw,
      common: t(errorsRaw.common)
    });
  }, [t]);

  function clearErrors() {
    setErrors(null);
  }

  function clearCommonError() {
    if (errors) {
      setErrors({
        ...errors,
        common: '',
      });
    }
  }

  return {
    errors,
    getErrors,
    clearErrors,
    clearCommonError,
  };
}
