import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useVisibilityState } from '@/processes/use-visibility-state';
import * as actionCreators from '@/store/actions';

import { getUnreadNotificationsCount } from '../api';

const NOTIFICATIONS_REQUEST_INTERVAL = 60000;

let intervalId = null;

export function useNotificationsCount(orgId) {
  const dispatch = useDispatch();
  const notificationsCount = useSelector((state) => state.view.notificationsAmount);

  const { tabIsVisible } = useVisibilityState();

  useEffect(() => {
    async function getNotificationsCount() {
      try {
        const data = await getUnreadNotificationsCount(orgId);

        dispatch(actionCreators.setNotificationsAmount(data.count));
      } catch (err) {
        console.error(err);
      }
    }

    function startLongPolling() {
      getNotificationsCount();

      if (intervalId) {
        stopLongPolling();
      }

      intervalId = setInterval(() => {
        getNotificationsCount();
      }, NOTIFICATIONS_REQUEST_INTERVAL);
    }

    function stopLongPolling() {
      clearInterval(intervalId);
    }

    if (tabIsVisible && orgId) {
      startLongPolling();
    } else {
      stopLongPolling();
    }

    return () => {
      stopLongPolling();
    }
  }, [orgId, tabIsVisible, dispatch])

  return {
    notificationsCount,
  };
}
