import * as actionTypes from './actionTypes';

export const setColorTheme = (darkMode) => ({
  type: actionTypes.SET_COLOR_THEME,
  darkMode,
});

export const addToast = (header, message, icon, hasCloseButton) => {
  return {
    type: actionTypes.ADD_TOAST,
    header,
    message,
    icon,
    hasCloseButton,
  };
};

export const removeToast = () => ({
  type: actionTypes.REMOVE_TOAST,
});

export const removeToastByIndex = (idx) => ({
  type: actionTypes.REMOVE_TOAST_BY_INDEX,
  index: idx,
});

export const setScreenSize = (breakpoint) => ({
  type: actionTypes.SET_SCREEN_SIZE,
  breakpoint,
});

export const setDefaultLoc = (loc) => ({
  type: actionTypes.SET_DEFAULT_LOC,
  loc,
});

export const setError = (error) => ({
  type: actionTypes.SET_ERROR,
  error,
});

export const setSpecialError = (errStatus, isFileError = false) => ({
  type: actionTypes.SET_SPECIAL_ERROR,
  errStatus,
  isFileError,
});

export const clearErrors = () => ({
  type: actionTypes.CLEAR_ERRORS,
});

export const setNotificationsAmount = (amount) => ({
  type: actionTypes.SET_NOTIFICATIONS_AMOUNT,
  amount,
});

export const setIsEditingNotifications = (isEditingNotifications) => ({
  type: actionTypes.SET_IS_EDITING_NOTIFICATIONS,
  isEditingNotifications,
});
