import { useParams, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setCurrentOrganizationId } from '@/store/actions/currentUser';

const NewOrgRedirect = () => {
  const dispatch = useDispatch();
  const { id: orgId } = useParams();
  if (orgId) {
    dispatch(setCurrentOrganizationId(Number(orgId)))
  }

  return <Navigate to="/" replace={true} />;
};

export default NewOrgRedirect;
