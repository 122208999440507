import { Suspense } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import LoadingSpinner from '@/components/reusable/LoadingSpinner';
import Toaster from '@/components/Toaster';
import TheFooter from '@/app/ui/TheFooter';

import { useSidebar } from '../hooks/use-sidebar';
import TheSidebar from './TheSidebar';
import TheHeader from './TheHeader';

import styles from './LayoutMain.module.css';

function LayoutMain() {
  const navigate = useNavigate();

  const { t } = useTranslation(['errors']);

  const error = useSelector((state) => state.view.error);
  const specialError = useSelector((state) => state.view.specialError);

  const breakpoint = useSelector((state) => state.view.breakpoint);

  const {
    isVisible: sidebarIsVisible,
    isMinimized,
    toggleSidebar,
    toggleMinimization,
  } = useSidebar(breakpoint === 'small' || breakpoint === 'medium' ? false : true);

  const classnames = [styles.wrapper];
  if (sidebarIsVisible) {
    classnames.push(isMinimized ? styles.wrapperWithSidebarMin : styles.wrapperWithSidebar)
  }

  if (specialError >= 500) {
    navigate('/error/500');
  }

  switch (specialError) {
    case 401:
      navigate('/auth/login');
      break;
    case 404:
      navigate('/error/404');
      break;
    default:
      break;
  }

  if (specialError >= 500 || error?.other === t('errors:unknownError')) {
    navigate('/error/500');
  }

  return (
    <div className={styles.root}>
      <TheSidebar
        isVisible={sidebarIsVisible}
        isMinimized={isMinimized}
        toggleSidebar={toggleSidebar}
        toggleMinimization={toggleMinimization}
      />
      <div className={cn(classnames)}>
        <TheHeader toggleSidebar={toggleSidebar} />
        <main className={styles.main}>
          <Suspense fallback={<LoadingSpinner isBetweenPages />}>
            <Outlet />
          </Suspense>
        </main>
        <TheFooter />
      </div>
      <Toaster/>
    </div>
  )
}


export default LayoutMain;
