import React from 'react';

import { BaseInput } from '@/shared/ui/input';
import { BaseButton } from '@/shared/ui/button';
import { formatDateLocalized } from '@/shared/utils/formatDateLocalized';
import { PLAN_NAMES } from '@/shared/constants';

import styles from './formCurrentPlanData.module.css';

export const formCurrentPlanData = ({
  t,
  currentOrganization,
  plan,
  currentLanguage,
  isOwner,
  isFetching,
  onClickUpgrade,
  licenseToUpgrade,
  setLicenseToUpgrade,
}) => {
  return [
    {
      label: 'billing_period',
      content: (
        <>
          {currentOrganization?.plan_period_end
            ? formatDateLocalized(
                new Date(currentOrganization.plan_period_end),
                'LLL dd yyyy',
                currentLanguage
              )
            : '-'}
        </>
      ),
    },
    {
      label: 'cost_per_device',
      content: (
        <>
          {plan?.price_cents ? `$ ${plan.price_cents / 100} / ${t('plans:year')}` : t('plans:free')}
        </>
      ),
    },
    {
      label: 'prepaid_device_connections',
      content: (
        <>
          {plan.name === PLAN_NAMES.pro && currentOrganization?.plan_licenses && isOwner ? (
            <div className={styles.update}>
              <BaseInput
                type="number"
                className={styles.devicesInput}
                value={licenseToUpgrade}
                minValue={1}
                onChange={setLicenseToUpgrade}
                isInteger
              />
              <BaseButton
                onClick={onClickUpgrade}
                loading={isFetching}
                disabled={isFetching || licenseToUpgrade === currentOrganization?.plan_licenses}
              >
                {t('plans:update')}
              </BaseButton>
            </div>
          ) : (
            currentOrganization?.plan_licenses || plan?.max_devices || '-'
          )}
        </>
      ),
    },
    plan.name !== PLAN_NAMES.enterprise && {
      label: 'total_cost',
      content: (
        <>
          {`$ ${(currentOrganization.plan_licenses * plan.price_cents) / 100} / ${t('plans:year')}`}
        </>
      ),
    },
    {
      label: 'connected_devices',
      content: <>{currentOrganization?.organization_devices_count || '0'}</>,
    },
    {
      label: 'air_quality_data_storage',
      content: (
        <>
          {plan?.max_days_history
            ? `${plan?.max_days_history} ${t('plans:days')}`
            : `${t('plans:unlimited')} ${plan?.description?.name?.toUpperCase()}`}
        </>
      ),
    },
  ];
};
