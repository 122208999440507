import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash-es/debounce';
import { ThemeProvider } from 'styled-components';

import { getBreakpoint } from '@/util/basic';
import Constants from '@/util/constants';
import * as actionCreators from '@/store/actions';
import CookiesBanner from '@/components/reusable/CookiesBanner';
import LoadingSpinner from '@/components/reusable/LoadingSpinner';
import ScreenWithBackground from '@/components/reusable/themes/styledComponents/ScreenWithBackground';

import '@/resources/styles/style.scss';

let dataFetchPerformed = false;

function Root({ children }) {
  const { i18n, t } = useTranslation(['errors']);

  const darkMode = useSelector((state) => state.view.darkMode);
  const isLoggedIn = useSelector((state) => state.authorization.isLoggedIn);

  const primaryFetch = useSelector((state) => state.currentUser.primaryFetch);
  const nonAuthorizedLang = useSelector((state) => state.currentUser.nonAuthorizedLang);

  const dispatch = useDispatch();

  useEffect(() => {
    onMount();

    return () => {
      onUnmount();
    }
  }, []);

  useEffect(() => {
    fetchInitData();
  }, [isLoggedIn]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--app-background',
      `${darkMode ? '#0c292f' : '#e5f5f4'}`
    );
  }, [darkMode]);

  const checkScreen = debounce(() => {
    const newBreakpoint = getBreakpoint(window.innerWidth);

    dispatch(actionCreators.setScreenSize(newBreakpoint));
  }, 40);

  async function fetchInitData() {
    await dispatch(actionCreators.getCurrentUserInfo(t, i18n, nonAuthorizedLang));
    dataFetchPerformed = true;
  }

  function onMount() {
    window.addEventListener('resize', checkScreen, false);
    document.addEventListener('keydown', (e) => checkKey(e));
    window.addEventListener('resize', applyViewPort);
    window.addEventListener('orientationchange', applyViewPort);

    applyViewPort();

    document.documentElement.style.setProperty(
      '--app-background',
      `${darkMode ? '#0c292f' : '#e5f5f4'}`
    );
  }

  function onUnmount() {
    window.removeEventListener('resize', checkScreen, false);
    document.removeEventListener('keydown', (e) => checkKey(e));
    window.removeEventListener('resize', applyViewPort);
    window.removeEventListener('orientationchange', applyViewPort);
  }

  function checkKey(e) {
    Object.defineProperty(e, 'targetKey', {
      value: e.key || Constants.placeholderKey,
    });
  };

  function applyViewPort() {
    // IOS: zoom trigger a resize event
    const isUserAgentIphone = window.navigator.userAgent.indexOf('iPhone') > -1;
    if (!isUserAgentIphone) {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    }
  };

  if (!dataFetchPerformed || primaryFetch) {
    return <LoadingSpinner />;
  }

  return (
    <ThemeProvider theme={{ main: darkMode ? 'dark' : 'light' }}>
      <ScreenWithBackground className={`c-app c-app--theme--${darkMode ? 'dark' : 'light'}`}>
        <CookiesBanner />
        {children}
      </ScreenWithBackground>
    </ThemeProvider>
  )
}

export default Root;
