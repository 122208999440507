import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AtmoTubeLogo } from '@/resources/assets/images/atmo-cube-logo.svg';

import styles from './Error500.module.css';

function Error500() {
  const { t } = useTranslation(['errors']);

  return (
    <div className={styles.page500}>
      <div className={styles.page500ColumnsContainer}>
        <div className={styles.logoColumn}>
          <div className={styles.logoWrapper}>
            <AtmoTubeLogo className={styles.logo} />
          </div>
          <div className={styles.logoSubscription}>AIR QUALITY COACH</div>
        </div>
        <div className={styles.descriptionColumns}>
          <div className={styles.errorExplain}>{t('errors:unavailable_service')}</div>
        </div>
      </div>
    </div>
  )
}

export default Error500;
