import styled from 'styled-components';
import darkBackground from '../../../../resources/assets/images/bg-dark.jpg';
import lightBackground from '../../../../resources/assets/images/bg.jpg';
import { themes as colorThemes } from '../styleAttributes/colors';

const ScreenWithBackground = styled.div`
  background: url(${({ theme: { main } }) => (main === 'dark' ? darkBackground : lightBackground)});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  color: ${({ theme: { main } }) => colorThemes[main].commonText};

  .auth-screen-logo {
    fill: ${({ theme: { main } }) => colorThemes[main].commonText} !important;
  }

  @media screen and (min-width: 992px) {
    background-attachment: fixed;
    height: 100vh;
  }

  @media screen and (max-width: 991px) {
    min-height: 100vh;
  }
`;

export default ScreenWithBackground;
