const COMMON_ERRORS_FIELDS = ['errors', 'non_field_errors', 'detail'];

function getCommonErrors(response) {
  const { status, data } = response;

  const firstPresentedCommonErrorField = COMMON_ERRORS_FIELDS.find((field) => data && Boolean(data[field]));

  if (firstPresentedCommonErrorField) {
    const dataErrors = data[firstPresentedCommonErrorField];
    return Array.isArray(dataErrors) ? dataErrors.join('\n') : dataErrors;
  } else if ((status && [401, 404, 500].includes(status)) || status > 500) {
    return `errors:got${status}`;
  }
}

function getErrorDetails(response) {
  const { data } = response;
  if (data?.error_details && data.error_details.length > 0) {
    return data.error_details[0]
  }
}

export function getBackendErrors(err) {
  const result = {};

  if (err.response) {
    const { data } = err.response;

    result.common = getCommonErrors(err.response);
    result.details = getErrorDetails(err.response)

    if (data && Object.keys(data).length) {
      const fields = {};

      for (let errorKey in data) {
        if (!COMMON_ERRORS_FIELDS.includes(errorKey) && errorKey !== 'error_details') {
          const errorsForField = data[errorKey];
          const newText = Array.isArray(errorsForField) ? errorsForField.join('\n') : errorsForField;

          fields[errorKey] = newText;
        }
      }

      result.fields = fields;
    }
  }

  return result;
}
