import axios from '@/util/axios-config';
import { AuthClass } from '@/util/auth';

export async function getUnreadNotificationsCount(orgId) {
  if (!orgId) {
    throw new Error('organization id has not provided');
  }

  const response = await axios.get(
    `organizations/${orgId}/user_notifications/get_not_viewed_count/`,
    { headers: AuthClass.authHeaders() }
  );

  return response.data;
}
