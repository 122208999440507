import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../util/axios-config';
import { AuthClass } from '../../util/auth';

const initialState = {
  floors: [],
  fetchFloorsError: null,
};

export const fetchAllFloors = createAsyncThunk(
  'devices/fetchAllFloors',
  async ({ currentOrganizationId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`organizations/${currentOrganizationId}/floors/`, {
        headers: AuthClass.authHeaders(),
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  }
);

const floorsSlice = createSlice({
  name: 'floors',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchAllFloors.fulfilled]: (state, action) => {
      state.floors = action.payload;
    },
    [fetchAllFloors.rejected]: (state, action) => {
      state.fetchFloorsError = action.payload;
    },
  },
});

export default floorsSlice.reducer;
