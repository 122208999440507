export class EventTracker {
  constructor(category = 'Atmocube Category') {
    this.category = category;
  }

  track(action = 'action', label = '', value = '') {
    const values = {};
    if (this.category) {
      values['event_category'] = this.category;
    }
    if (label && label.length !== 0) {
      values['event_label'] = label;
    }
    if (value && value.length !== 0) {
      values['event_value'] = value;
    }

    window.gtag('event', action, values);
  }
}
