import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

const AuthorizationLogicRoute = ({ children }) => {
  const { pathname } = useLocation();

  const isLoggedIn = useSelector((state) => state.authorization.isLoggedIn);
  const mfaRequired = useSelector((state) => state.authorization.mfaRequired);
  const isVerified = useSelector((state) => state.authorization.isVerified);

  let redirect = null;

  if (mfaRequired) {
    redirect='/auth/two_factor_auth';
  } else if (isLoggedIn && isVerified === false) {
    redirect = '/auth/confirm_email';
  }

  if (redirect && redirect !== pathname) {
    return <Navigate to={redirect} />
  }

  return children;
};

export default AuthorizationLogicRoute;
