export function getCodeStatus(status) {
  switch (status) {
    case 'p':
      return 'pending';
    case 'r':
      return 'rejected';
    case 'a':
      return 'activated';
    default:
      return '';
  }
}
