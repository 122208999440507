import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import styled from 'styled-components';

import { BaseButton } from '@/shared/ui/button';
import { BaseLink } from '@/shared/ui/link';
import Constants from '../../util/constants';
import { LINKS } from '@/shared/constants';

const cookies = new Cookies();

export const getCookie = (name, path = '/') => {
  return cookies.get(name, { path });
};

export const setCookie = (name, value, expires, path = '/') => {
  cookies.set(name, value, { expires, path });
};

export const setBannerCookie = () => {
  const expirationDate = new Date();
  let time = expirationDate.getTime();
  time += Constants.year;
  expirationDate.setTime(time);
  setCookie('cookieAgreement', true, expirationDate);
};

const CookiesBanner = ({ debug }) => {
  const [isBannerVisible, setBannerVisible] = useState(false);

  useEffect(() => {
    if (!getCookie('cookieAgreement') || debug) {
      setBannerVisible(true);
    }
  }, []);

  return isBannerVisible ? (
    <div>
      <CookiesBannerContainer>
        <CookiesBannerContent>
          <p>This website uses cookies to ensure you get the best experience on our website.</p>
          <BaseLink href={LINKS.cookiesLearnMoreLink} target="_blank" rel="noreferrer">
            Learn more
          </BaseLink>
        </CookiesBannerContent>
        <ButtonWrapper>
          <BaseButton
            onClick={() => {
              setBannerCookie();
              setBannerVisible(false);
            }}
            style={{ height: '40px' }}
          >
            Got it!
          </BaseButton>
        </ButtonWrapper>
      </CookiesBannerContainer>
    </div>
  ) : (
    ''
  );
};

export default CookiesBanner;

const CookiesBannerContainer = styled.div`
  align-items: center;
  background: #353535;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 2000;
  opacity: 0.9;
`;
const CookiesBannerContent = styled.div`
  flex: 1 0 300px;
  margin: 15px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 15px;
`;
