import React from 'react';

function retryLoad(componentImport, name = 'default') {
  return new Promise((resolve, reject) => {
    const lazyLoadRefreshed = JSON.parse(window.sessionStorage.getItem('lazyLoadRefreshed') || '{}')

    // check if the window has already been refreshed
    const hasRefreshed = lazyLoadRefreshed?.[name];

    // try to import the component
    componentImport().then((component) => {
      // success so reset the refresh
      lazyLoadRefreshed[name] = false;
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed) { // not been refreshed yet
        lazyLoadRefreshed[name] = true;
        return window.location.reload();
      }

      reject(error); // Default error behaviour as already tried refresh
    }).finally(() => {
      window.sessionStorage.setItem('lazyLoadRefreshed', JSON.stringify(lazyLoadRefreshed));
    });
  });
}

export const reactLazyLoad = (componentImport, name) => React.lazy(() => retryLoad(componentImport, name));
