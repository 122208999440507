import React from 'react';
import cn from 'classnames';
import styles from './BaseInput.module.css';

const BaseInput = React.forwardRef(
  (
    {
      className,
      value,
      placeholder,
      status,
      disabled,
      type,
      minValue,
      maxValue,
      renderIcon,
      rounded = true,
      contrast = false,
      formatValue,
      onChange,
      onFocus,
      onBlur,
      isInteger = false,
      ...otherProps
    },
    ref
  ) => {
    const classnames = [
      styles.root,
      status && styles[`status--${status}`],
      rounded && styles.rootRounded,
      contrast && styles.rootContrast,
      renderIcon && styles.withIcon,
      className,
    ];

    function handleInput(event) {
      if (typeof formatValue === 'function') {
        const newValue = formatValue(event.target.value);
        event.target.value = newValue;
      }
    }

    function handleChange(event) {
      if (!onChange) {
        return;
      }
      if ((minValue || minValue === 0) && minValue > event.target.value) {
        onChange(minValue);
        return
      }
      if (maxValue && maxValue < event.target.value) {
        onChange(maxValue);
        return
      }
      onChange(isInteger ? Math.round(event.target.value) : event.target.value);
    }

    function handleFocus() {
      if (!onFocus) {
        return;
      }

      onFocus();
    }

    function handleBlur() {
      if (!onBlur) {
        return;
      }

      onBlur();
    }

    const handleDecrease = () => {
      if (minValue < value) {
        onChange(value - 1);
      }
    };

    const handleIncrease = () => {
      onChange(Number(value) + 1);
    }

    return (
      <div ref={ref} className={cn(classnames)}>
        <input
          className={styles.input}
          value={value}
          type={type || 'text'}
          placeholder={placeholder}
          disabled={disabled}
          {...otherProps}
          onInput={handleInput}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {type === 'number' && (
          <div className={styles.numberInput}>
            <div
              className={styles.numberInputUp}
              onClick={handleIncrease}
            >
              {'\u25B2'}
            </div>
            <div className={styles.numberInputDown} onClick={handleDecrease}>
              {'\u25BC'}
            </div>
          </div>
        )}
        {renderIcon && <span className={styles.iconWrapper}>{renderIcon()}</span>}
      </div>
    );
  }
);

export default BaseInput;
