import { combineReducers } from 'redux';
import authorizationReducer from './authorization';
import viewReducer from './view';
import currentUserReducer from './currentUser';
import commandsReducer from './commands';
import devicesReducer from './devices';
import floorsReducer from './floors';
import measurementsReducer from './measurements';

export const rootReducer = combineReducers({
  authorization: authorizationReducer,
  view: viewReducer,
  currentUser: currentUserReducer,
  devices: devicesReducer,
  commands: commandsReducer,
  floors: floorsReducer,
  measurements: measurementsReducer,
});
