export const ERROR_DETAILS_CODES = {
  requestLimit: 'request_limit',
  mfaDisabled: 'mfa_disabled',
  mfaMaxTries: 'mfa_max_tries',
  onlyOneOrganizationAllowed: 'only_one_organization_allowed',
  onlyOwnerCanRemoveOwner: 'only_owner_can_remove_owner',
  shouldNotContainDevices: 'should_not_contain_devices',
  deviceIsOffline: 'device_is_offline',
  deviceNotFound: 'device_not_found',
  couldNotGetMeasurement_data: 'could_not_get_measurement_data',
  usersMismatch: 'users_mismatch',
  sendEmailMaxTries: 'send_email_max_tries',
  wrongRoleForAction: 'wrong_role_for_action',
  cantChangeOwner: 'cant_change_owner',
  alreadyOwner: 'already_owner',
  ownerCannotBeRemoved: 'owner_cannot_be_removed',
  userNotFound: 'user_not_found',
  codeExpired: 'code_expired',
  codeMaxTried: 'code_max_tried',
  wrongCode: 'wrong_code',
  maxDevicesReached: 'max_devices_reached',
  invalidDeviceId: 'invalid_device_id',
  dateStartRequired: 'date_start_required',
  dateEndRequired: 'date_end_required',
  deviceParamRequired: 'device_param_required',
  fieldNotValid: 'field_not_valid',
  noOffset: 'no_offset',
  invalidOffset: 'invalid_offset',
  offsetOutOfRange: 'offset_out_of_range',
  noPermission: 'no_permission',
}
