import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components'
import { CSpinner } from '@coreui/react';
import cn from 'classnames';

import styles from './BaseButton.module.css';

const BaseButton = ({
  className,
  children,
  type = 'button',
  color = 'primary',
  variant,
  compressed = false,
  block = false,
  loading = false,
  ...other
}) => {
  const themeContext = useContext(ThemeContext);

  const classnames = [
    styles.root,
    compressed && styles.rootCompressed,
    block && styles.rootBlock,
    styles[`theme--${themeContext.main}`],
    styles[`color--${color}`],
    styles[variant ? `variant--${variant}` : ''],
    className,
  ];

  return (
    <button
      className={cn(classnames)}
      type={type}
      {...other}
    >
      {loading && !['link'].includes(color) && (
        <div className={styles.spinnerWrapper}>
          <CSpinner style={{ width: 18, height: 18 }} />
        </div>
      )}
      {children}
    </button>
  );
};

export default BaseButton;
