import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../util/axios-config';
import { AuthClass } from '../../util/auth';
import { now } from '../../util/basic';

const initialState = {
  fetchMeasurementsError: null,
  measurementsHistory: {},
  fetchChartsError: null,
  chartsStartDate: now(),
  chartsEndDate: now(),
  chartsFetching: {},
};

export const fetchChartsData = createAsyncThunk(
  `charts/fetchChartsData`,
  async ({ currentOrganizationId, params }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`organizations/${currentOrganizationId}/measurements/2/`, {
        headers: AuthClass.authHeaders(),
        params,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data);
    }
  }
);

const measurementsSlice = createSlice({
  name: 'measurements',
  initialState,
  reducers: {
    setChartsStartDate: (state, action) => {
      state.chartsStartDate = action.payload;
    },
    setChartsEndDate: (state, action) => {
      state.chartsEndDate = action.payload;
    },
    setMeasurementsHistory: (state, action) => {
      state.measurementsHistory = action.payload;
    },
  },
  extraReducers: {
    [fetchChartsData.pending]: (state, action) => {
      const sensors = action.meta.arg.params?.data_fields?.split(',');
      sensors.forEach((sensor) => {
        state.chartsFetching[sensor] = true;
      })
    },
    [fetchChartsData.fulfilled]: (state) => {
      Object.keys(state.chartsFetching).forEach((key) => {
        state.chartsFetching[key] = false;
      });
    },
    [fetchChartsData.rejected]: (state, action) => {
      state.fetchChartsError = action.payload;
      Object.keys(state.chartsFetching).forEach((key) => {
        state.chartsFetching[key] = false;
      });
    },
  },
});

export const { setChartsEndDate, setChartsStartDate, setMeasurementsHistory } = measurementsSlice.actions;
export default measurementsSlice.reducer;
