import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

import { usePermissions } from '@/processes/use-permissions';

const NeedDeviceRoute = ({ children }) => {
  const { pathname } = useLocation();

  const hasDevices = useSelector((state) => state.currentUser.hasDevices);

  const { isViewer } = usePermissions();

  let redirect = null;

  if (!hasDevices) {
    redirect = !isViewer ? '/devices/add' : '/access_error';
  }

  if (redirect && redirect !== pathname) {
    return <Navigate to={redirect} />
  }

  return children;
};

export default NeedDeviceRoute;
