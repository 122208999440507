import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';


const NeedOrganizationRoute = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;

  const organizations = useSelector((state) => state.currentUser.organizations);

  let redirect = null;

  if (organizations.length === 0 && pathname !== '/help_desk_admin') {
    redirect = '/create_workspace';
  }

  if (redirect && redirect !== pathname) {
    return <Navigate to={redirect} />
  }

  return children;
};

export default NeedOrganizationRoute;
