import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/shared/ui/button';

import { PLANS_ADDITIONAL_BENEFITS_LIST, PLANS_BENEFITS_LIST, PLAN_NAMES } from '@/shared/constants';
import { BaseLink } from '@/shared/ui/link';

import styles from './PlansData.module.css';

export const PlansData = ( {freePlan, proPlan, enterprisePlan }) => {
  const navigate = useNavigate();

  const { i18n, t } = useTranslation(['plans']);
  const currentLanguage = i18n.language;

  const mainData = PLANS_BENEFITS_LIST.map((el) => (
    <tr key={el}>
      <td key={`plans:${el}`}>{t(`plans:${el}`)}</td>
      {[freePlan, proPlan, enterprisePlan].map((plan) => (
        <td key={plan.name} className={plan.name === PLAN_NAMES.enterprise ? styles.enterpriseBenefits : ''}>
          {el === 'api' && plan.description?.details[el] ? (
            <BaseLink href={plan.description?.api_link} target="_blank">
              {plan.description?.details[el][currentLanguage]}
            </BaseLink>
          ) : plan.description?.details[el] ? (
            plan.description?.details[el][currentLanguage]
          ) : (
            '-'
          )}
        </td>
      ))}
    </tr>
  ));

  const additionalData = PLANS_ADDITIONAL_BENEFITS_LIST.map((el) => (
    <tr key={el}>
      <td key={`plans:${el}`} className={styles.enterpriseBenefits}>
        {t(`plans:${el}`)}
      </td>
      {[freePlan, proPlan, enterprisePlan].map((plan) => {
        return (
          <td key={plan.name} className={styles.enterpriseBenefits}>
            {plan.description?.details[el] ? plan.description?.details[el][currentLanguage] : '-'}
          </td>
        );
      })}
    </tr>
  ));

  const price = (plan) => (
    <>
      {plan.name !== PLAN_NAMES.enterprise ? (
        <>
          <span className={styles.price}>{plan.price_cents / 100} USD </span>
          <br/>
          {t('plans:per_device')}
        </>
      ) : (
        <BaseButton block onClick={() => navigate('/plan_upgrade')}>
          {t('plans:request')}
        </BaseButton>
      )}
    </>
  );

  return <>
    <thead>
      <tr className={styles.title}>
        <td className={styles.noBorder}></td>
        <td>{freePlan.description?.name?.toUpperCase()}</td>
        <td>{proPlan.description?.name?.toUpperCase()}</td>
        <td className={styles.enterpriseBenefits}>
          {enterprisePlan.description?.name?.toUpperCase()}
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className={styles.noBorder}></td>
        <td>{price(freePlan)}</td>
        <td>{price(proPlan)}</td>
        <td className={styles.enterpriseBenefits}>{price(enterprisePlan)}</td>
      </tr>
      {mainData}
      {additionalData}
    </tbody>
  </>
};
