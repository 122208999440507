import React from 'react';

export default class Constants {
  static year = 31536000000;

  static aqiArray = [
    { code: 'US', name: 'United States' },
    { code: 'EU', name: 'Europe' },
    { code: 'HK', name: 'Hong Kong' },
    { code: 'UK', name: 'United Kingdom' },
    { code: 'AU', name: 'Australia' },
    { code: 'CA', name: 'Canada' },
    { code: 'IN', name: 'India' },
    { code: 'CN', name: 'Mainland China' },
    { code: 'SG', name: 'Singapore' },
    { code: 'KR', name: 'South Korea' },
  ];

  static supportedLanguages = [
    { code: 'en' },
    // { code: 'de' },
    // { code: 'ru' },
    // { code: 'fr' },
    // { code: 'es' },
    // { code: 'it' },
    { code: 'pt' },
  ];

  static iconsByLanguages = new Map([
    ['en', 'cif-us'],
    ['de', 'cif-de'],
    ['ru', 'cif-ru'],
    ['fr', 'cif-fr'],
    ['es', 'cif-es'],
    ['it', 'cif-it'],
    ['pt', 'cif-pt'],
  ]);

  static intervals = [
    {
      id: 15,
    },
    {
      id: 30,
    },
    {
      id: 60,
    },
    {
      id: 720,
    },
    {
      id: 1440,
    },
  ];

  static units = [
    { id: 'M', name: 'Metric' },
    { id: 'I', name: 'Imperial' },
  ];

  static MAX_PICKED_DEVICES_COUNT = 6;
  static pickedDeviceColours = ['#00b5b5', '#6467d7', '#ec9631', '#ec4364', '#71c2ff', '#b0c351'];

  static measurements = [
    {
      code: 'temperature',
      unit: {
        M: <React.Fragment>&deg;C</React.Fragment>,
        I: <React.Fragment>&deg;F</React.Fragment>,
      },
      min: {
        M: -40,
        I: -40,
      },
      max: {
        M: 125,
        I: 257,
      },
      defaultMin: {
        M: 18,
        I: 64.4,
      },
      defaultMax: {
        M: 27,
        I: 80.6,
      },
      twoBorders: true,
      float: true,
      accuracy: 1,
    },
    {
      code: 'humidity',
      unit: '%',
      twoBorders: true,
      min: 0,
      max: 100,
      defaultMin: 40,
      defaultMax: 60,
      accuracy: 0,
    },
    {
      code: 'abs_humidity',
      unit: (
        <React.Fragment>
          g/m<sup>3</sup>
        </React.Fragment>
      ),
      onlyDashboard: true,
      noNotification: true,
      accuracy: 0,
      display: 'Abs Humidity',
    },
    {
      code: 'voc',
      display: 'TVOC',
      unit: 'ppm',
      float: true,
      min: 0,
      max: 60,
      defaultMax: 0.5,
      accuracy: 3,
      schema: 1,
    },
    {
      code: 'noxindex',
      display: 'NOx index',
      unit: '',
      float: true,
      min: 0,
      max: 300,
      accuracy: 0,
      schema: 2,
    },
    {
      code: 'vocindex',
      display: 'VOC index',
      unit: '',
      float: true,
      min: 0,
      max: 300,
      accuracy: 0,
      schema: 2,
    },
    {
      code: 'co2',
      display: (
        <React.Fragment>
          CO<sub>2</sub>
        </React.Fragment>
      ),
      unit: 'ppm',
      min: 0,
      max: 5000,
      defaultMax: 10,
      accuracy: 0,
    },
    {
      code: 'co',
      display: 'CO',
      unit: 'ppm',
      float: true,
      min: 0,
      max: 500,
      defaultMax: 10,
      accuracy: 3,
    },
    {
      code: 'pm1',
      display: (
        <React.Fragment>
          PM<sub>1</sub>
        </React.Fragment>
      ),
      unit: (
        <React.Fragment>
          &mu;g/m<sup>3</sup>
        </React.Fragment>
      ),
      float: true,
      min: 0,
      max: 1000,
      defaultMax: 30,
      accuracy: 1,
    },
    {
      code: 'pm25',
      display: (
        <React.Fragment>
          PM<sub>2.5</sub>
        </React.Fragment>
      ),
      unit: (
        <React.Fragment>
          &mu;g/m<sup>3</sup>
        </React.Fragment>
      ),
      float: true,
      min: 0,
      max: 1000,
      defaultMax: 50,
      accuracy: 1,
    },
    {
      code: 'pm10',
      display: (
        <React.Fragment>
          PM<sub>10</sub>
        </React.Fragment>
      ),
      unit: (
        <React.Fragment>
          &mu;g/m<sup>3</sup>
        </React.Fragment>
      ),
      float: true,
      min: 0,
      max: 1000,
      defaultMax: 80,
      accuracy: 1,
    },
    {
      code: 'pm4',
      display: (
        <React.Fragment>
          PM<sub>4</sub>
        </React.Fragment>
      ),
      unit: (
        <React.Fragment>
          &mu;g/m<sup>3</sup>
        </React.Fragment>
      ),
      float: true,
      onlyDashboard: true,
      min: 0,
      max: 1000,
      noNotification: true,
      accuracy: 1,
    },
    {
      code: 'pm05_num',
      display: (
        <React.Fragment>
          PM<sub>0.5</sub>
        </React.Fragment>
      ),
      unit: (
        <React.Fragment>
          #/cm<sup>3</sup>
        </React.Fragment>
      ),
      additionalInfo: 'number_concentration',
      min: 0,
      max: 3000,
      defaultMax: 30,
      accuracy: 1,
      onlyDashboard: true,
      noNotification: true,
    },
    {
      code: 'pm1_num',
      display: (
        <React.Fragment>
          PM<sub>1</sub>
        </React.Fragment>
      ),
      unit: (
        <React.Fragment>
          #/cm<sup>3</sup>
        </React.Fragment>
      ),
      additionalInfo: 'number_concentration',
      min: 0,
      max: 3000,
      defaultMax: 30,
      accuracy: 1,
      onlyDashboard: true,
      noNotification: true,
    },
    {
      code: 'pm25_num',
      display: (
        <React.Fragment>
          PM<sub>2.5</sub>
        </React.Fragment>
      ),
      unit: (
        <React.Fragment>
          #/cm<sup>3</sup>
        </React.Fragment>
      ),
      additionalInfo: 'number_concentration',
      min: 0,
      max: 3000,
      defaultMax: 50,
      accuracy: 1,
      onlyDashboard: true,
      noNotification: true,
    },
    {
      code: 'pm10_num',
      display: (
        <React.Fragment>
          PM<sub>10</sub>
        </React.Fragment>
      ),
      unit: (
        <React.Fragment>
          #/cm<sup>3</sup>
        </React.Fragment>
      ),
      additionalInfo: 'number_concentration',
      min: 0,
      max: 3000,
      defaultMax: 80,
      accuracy: 1,
      onlyDashboard: true,
      noNotification: true,
    },
    {
      code: 'pm4_num',
      display: (
        <React.Fragment>
          PM<sub>4</sub>
        </React.Fragment>
      ),
      unit: (
        <React.Fragment>
          #/cm<sup>3</sup>
        </React.Fragment>
      ),
      additionalInfo: 'number_concentration',
      min: 0,
      max: 3000,
      accuracy: 1,
      onlyDashboard: true,
      noNotification: true,
    },
    {
      code: 'noise',
      unit: 'db',
      min: 0,
      max: 130,
      defaultMax: 70,
      onlyDashboard: true,
      noNotification: true,
      accuracy: 0,
    },
    {
      code: 'no2',
      display: (
        <React.Fragment>
          NO<sub>2</sub>
        </React.Fragment>
      ),
      unit: 'ppm',
      float: true,
      min: 0,
      max: 5,
      defaultMax: 0.101,
      accuracy: 3,
    },
    {
      code: 'o3',
      display: (
        <React.Fragment>
          O<sub>3</sub>
        </React.Fragment>
      ),
      unit: 'ppm',
      float: true,
      min: 0,
      max: 10,
      defaultMax: 0.13,
      accuracy: 3,
    },
    {
      code: 'ch2o',
      display: (
        <React.Fragment>
          CH<sub>2</sub>O
        </React.Fragment>
      ),
      unit: 'ppm',
      float: true,
      min: 0,
      max: 5,
      defaultMax: 0.1,
      accuracy: 3,
    },
    {
      code: 'light',
      unit: { M: 'lux', I: 'ftc' },
      onlyDashboard: true,
      min: { M: 0, I: 0 },
      max: { M: 10000, I: 930 },
      defaultMin: { M: 300, I: 27.87 },
      defaultMax: { M: 500, I: 46.5 },
      noNotification: true,
      accuracy: { M: 0, I: 1 },
      display: 'Light',
    },
    {
      code: 'pressure',
      unit: { M: 'mbar', I: 'inHg' },
      onlyDashboard: true,
      float: true,
      min: { M: 300, I: 8.85 },
      max: { M: 1200, I: 35.5 },
      noNotification: true,
      accuracy: 0,
      display: 'Pressure',
    },
    {
      code: 'people',
      display: 'People index',
      unit: '',
      min: 0,
      max: 100,
      accuracy: 0,
    },
  ];

  static textMeasurements = [
    'temperature',
    'humidity',
    'noise',
    'light',
    'pressure',
    'abs_humidity',
    'people',
  ];

  static basicMeasurementsColours = {
    //for all except noise/temp/hum
    good: '#48d0b5',
    moderate: '#b0c351',
    polluted: '#ffb548',
    'very polluted': '#ee6235',
    'severely polluted': '#d71c54',
    //for Noise
    quiet: '#48d0b5',
    average: '#b0c351',
    loud: '#ffB548',
    harmful: '#ee6235',
    dangerous: '#d71c54',
    // for Temp/Hum
    'very uncomfortable': '#d71c54',
    uncomfortable: '#ee6235',
    'slightly uncomfortable': '#ffb548',
    comfortable: '#b0c351',
    'very comfortable': '#48d0b5',
  };

  static aqiColours = {
    6: ['#48d0d0', '#77c7ff', '#9187ff', '#ffb548', '#ee6235', '#d71c54'],
    5: ['#48d0d0', '#b0c351', '#ffb548', '#ee6235', '#d71c54'],
    4: ['#48d0d0', '#9187ff', '#ffb548', '#d71c54'],
  };

  static notBasicMeasurementsColor = '#8293a2';

  static deviceStatuses = [
    { name: 'Online', value: 'online' },
    { name: 'Offline', value: 'offline' },
  ];

  static eiaqiLevels = ['good', 'moderate', 'polluted', 'very polluted', 'severely polluted'];

  static aqiLevels = {
    US: [
      'good',
      'moderate',
      'unhealthy for sensitive groups',
      'unhealthy',
      'very unhealthy',
      'hazardous',
    ],
    CA: ['low', 'moderate', 'high', 'very high'],
    EU: ['very low', 'low', 'medium', 'high', 'very high'],
    UK: ['low', 'moderate', 'high', 'very high'],
    AU: ['very good', 'good', 'fair', 'poor', 'very poor', 'hazardous'],
    SG: ['good', 'moderate', 'unhealthy', 'very unhealthy', 'hazardous'],
    KR: ['good', 'moderate', 'unhealthy', 'very unhealthy'],
    HK: ['low', 'moderate', 'high', 'very high', 'serious'],
    CN: [
      'excellent',
      'good',
      'lightly polluted',
      'moderately polluted',
      'heavily polluted',
      'severely polluted',
    ],
    IN: ['good', 'satisfactory', 'moderately polluted', 'poor', 'very poor', 'severe'],
  };

  static codeMirror = {
    jsBeautyConfig: {
      indent_size: 2,
      space_in_empty_paren: true,
      brace_style: 'end-expand',
      keep_array_indentation: false,
    },
  };

  static userRoles = [{ name: 'Admin' }, { name: 'Owner' }, { name: 'Viewer' }, { name: 'Custom' }];

  static alwaysAvailableStatOptions = ['iaqi', 'avti'];

  static arrayIntegerSensors = [
    'iaqi',
    'avti',
    'aqi',
    'ieaqi',
    'tci',
    'co2',
    'noise',
    'light',
    'abs_humidity',
    'vocindex',
    'noxindex',
    'humidity',
    'pressure',
    'pm1_num',
    'pm25_num',
    'pm10_num',
    'pm4_num',
    'pm05_num',
  ];

  static arrayWithOneDecimalPoint = ['pm1', 'pm25', 'pm10', 'pm4', 'temperature'];

  static arrayWithTwoDecimalPoints = [];

  static arrayWithThreeDecimalPoints = ['voc', 'co', 'o3', 'ch2o', 'no2'];

  static locPointerBackgroundWidth = 64;
  static locPointerBackgroundHeight = 64;
  static locPointerBackgroundWidthSmall = 40;
  static locPointerBackgroundHeightSmall = 40;
  static locPointerContainerWidth = 37;
  static locPointerContainerHeight = 30;

  static placeholderKey = 'placeholderKey';

  static activatePathname = /^\/activate\/\w+\/\w+\/[\w-]+$/;
  static proceedInvitationPathname = /^\/proceed_invitation\/\w+\/[\w-]+$/;
  static dashboardRedirectPathname = /^\/dashboard_redirect\/\w+$/;
  static confirmTransferPathname = /^\/confirm_transfer\/\w+\/\w+\/[\w-]+$/;

  static pollingInterval = 60000;
}
