import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useMatches } from 'react-router-dom';
import cn from 'classnames';

import styles from './TheBreadcrumbs.module.css';

const TheBreadcrumbItem = ({ path, text }) => {
  return (
    <NavLink
      className={({ isActive }) =>
        cn(styles.item, isActive && styles.itemActive)
      }
      to={path}
      end
    >
      <span className={styles.itemText}>{text}</span>
    </NavLink>
  );
};

const TheBreadcrumbs = ({ className }) => {
  const { t } = useTranslation(['routes']);

  const matches = useMatches();

  // Strictly false. By default we consider crumb is true
  const matchesWithCrumbs = matches.filter((match) => match.handle?.crumb !== false);

  const translatedCrumbs = matchesWithCrumbs.map((match) => {
    const name = match.handle?.name || match.pathname.split('/').filter(Boolean).join('_');

    return {
      name,
      path: match.pathname,
      text: t(`routes:${name}`),
    };
  });

  return (
    <nav className={cn(styles.root, className)}>
      {translatedCrumbs.map(({ path, name, text }) => {
        return <TheBreadcrumbItem key={name} path={path} text={text} />;
      })}
    </nav>
  );
};

export default TheBreadcrumbs;
