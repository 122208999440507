import { useState, useEffect } from 'react';

export function useVisibilityState() {
  const [tabIsVisible, setTabIsVisible] = useState(true);

  function onChangeVisibility() {
    if (document.visibilityState === 'visible') {
      setTabIsVisible(true);
    } else {
      setTabIsVisible(false);
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', onChangeVisibility);

    return () => {
      document.removeEventListener('visibilitychange', onChangeVisibility);
    }
  }, [])

  return {
    tabIsVisible,
  };
}
