import { AuthClass } from '../../util/auth';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isLoggedIn: AuthClass.isLoggedIn(),
  isVerified: false,
  mfaRequired: false,
  verificationError: null,
  authorizationError: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.authorization.SET_AUTHORIZATION_ERROR:
      return { ...state, authorizationError: action.authorizationError };
    case actionTypes.authorization.CLEAR_AUTHORIZATION_ERROR:
      return { ...state, authorizationError: null };
    case actionTypes.authorization.SIGN_UP_SUCCESS:
      AuthClass.saveCredentials(action.authToken);
      return { ...state, isLoggedIn: true };
    case actionTypes.authorization.LOG_IN_SUCCESS:
      if (!action.mfaRequired) {
        AuthClass.saveCredentials(action.authToken, 'token');
        return { ...state, isLoggedIn: true, mfaRequired: false, isVerified: action.isVerified };
      } else {
        AuthClass.saveCredentials(action.authToken, 'temporaryToken');
        return { ...state, isLoggedIn: false, mfaRequired: true };
      }
    case actionTypes.authorization.FINAL_LOGIN_SUCCESS:
      AuthClass.saveCredentials(action.authToken, 'token');
      return { ...state, isLoggedIn: true, mfaRequired: false };
    case actionTypes.authorization.CHECK_CODE_REQUEST:
      return { ...state, isLoggedIn: false, mfaRequired: true };
    case actionTypes.authorization.CHECK_CODE_SUCCESS:
      AuthClass.saveCredentials(action.authToken);
      return { ...state, isLoggedIn: true, mfaRequired: false, isVerified: action.isVerified };
    case actionTypes.authorization.CHECK_VERIFICATION_REQUEST:
      return { ...state, verificationError: null };
    case actionTypes.authorization.CHECK_VERIFICATION_SUCCESS:
      const { isVerified } = action;
      return {
        ...state,
        isVerified,
        verificationError: isVerified ? null : "You haven't verified your account",
      };
    case actionTypes.authorization.LOG_OUT:
      AuthClass.signOut();
      return { ...initialState, isLoggedIn: false };
    case actionTypes.authorization.VERIFY_GUARANTEED:
      return { ...state, isVerified: true };
    case actionTypes.currentUser.GET_CURRENT_USER_INFO_SUCCESS:
      return { ...state, isVerified: action.isVerified };
    case actionTypes.CLEAR_ERRORS:
      return { ...state, verificationError: null };
    case actionTypes.authorization.FINISH_INVITATION_SUCCESS:
      AuthClass.saveCredentials(action.authToken);
      return { ...state, isVerified: action.isVerified, isLoggedIn: true };
    default:
      return state;
  }
};

export default reducer;
