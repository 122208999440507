// The ratio of the value in the metric system to the provided one is defined
const UNITS_RATIO = {
  length: {
    I: 3.28084,
  },
  area: {
    I: 10.764,
  },
};

export function convertFromMetric(value, userUnits, measurement) {
  if (userUnits === 'M') {
    return Math.round(value * 100) / 100;
  }
  if (!value) {
    return value;
  }

  const ratio = UNITS_RATIO[measurement]?.[userUnits];
  if (!ratio) {
    throw new Error('Wrong measurement or unit was provided');
  }

  return Math.round(value * ratio * 100) / 100
}

export function convertToMetric(value, userUnits, measurement) {
  if (userUnits === 'M') {
    return value;
  }
  if (!value) {
    return value;
  }

  const ratio = UNITS_RATIO[measurement]?.[userUnits];
  if (!ratio) {
    throw new Error('Wrong measurement or unit was provided');
  }

  return value / ratio;
}
