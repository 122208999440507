import en_US from 'date-fns/locale/en-US';
import pt from 'date-fns/locale/pt';

const LOCALE_CODE_TO_LOCALE = {
  en: en_US,
  pt: pt,
}

export function getDateFnsLocaleObject(localeCode) {
  return LOCALE_CODE_TO_LOCALE[localeCode] || en_US;
}
