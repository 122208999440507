import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';

import BaseDropdown from '@/shared/ui/dropdown/BaseDropdown';
import { BaseLink } from '@/shared/ui/link';
import Constants from '../../util/constants';
import * as actionCreators from '../../store/actions';

const LangPicker = () => {
  const { i18n, t } = useTranslation(['general']);
  const isLoggedIn = useSelector((state) => state.authorization.isLoggedIn);

  const dispatch = useDispatch();

  function onChooseLanguage({ code }) {
    dispatch(actionCreators.clearErrors());
    dispatch(actionCreators.langChange(code, isLoggedIn, i18n));
  }

  return (
    <BaseDropdown placement="top-start">
      <CDropdownToggle caret={false} custom={true}>
        <BaseLink href="#">{t('general:language')}</BaseLink>
      </CDropdownToggle>
      <CDropdownMenu>
        {Constants.supportedLanguages.map((item) => (
          <CDropdownItem
            active={item.code === i18n.language}
            key={item.code}
            onClick={() => onChooseLanguage(item)}
          >
            {t(`general:langNames.${item.code}`)}
          </CDropdownItem>
        ))}
      </CDropdownMenu>
    </BaseDropdown>
  );
};

export default LangPicker;
