import { format } from 'date-fns';

import { getDateFnsLocaleObject } from '@/shared/utils/dates';

export const formatDateLocalized = (date, formatStr, currentLanguage) => {
  if (!(date instanceof Date)) {
    return '';
  }
  return format(date, formatStr, {
    locale: getDateFnsLocaleObject(currentLanguage),
  });

}
