const useAnalyticsEventTracker = (category = 'Atmocube Category') => {
  const trackEvent = (action = 'action', label = '', value = '') => {
    const values = {};
    if (category && category.length !== 0) {
      values['event_category'] = category;
    }
    if (label && label.length !== 0) {
      values['event_label'] = label;
    }
    if (value && value.length !== 0) {
      values['event_value'] = value;
    }
    window.gtag('event', action, values);
  };

  return trackEvent;
};

export default useAnalyticsEventTracker;
