import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

const AuthorizedRoute = ({ children }) => {
  const { pathname } = useLocation();

  const isLoggedIn = useSelector((state) => state.authorization.isLoggedIn);
  const isVerified = useSelector((state) => state.authorization.isVerified);

  let redirect = null;

  if (!isLoggedIn) {
    redirect = '/auth/login';
  }

  if (isLoggedIn && isVerified === false) {
    redirect = '/auth/confirm_email';
  }

  if (redirect && redirect !== pathname) {
    return <Navigate to={redirect} />
  }

  return children;
};

export default AuthorizedRoute;
