import React from 'react';
import { useSelector } from 'react-redux';
import { CSpinner } from '@coreui/react';
import darkBackground from '../../resources/assets/images/bg-dark.jpg';
import lightBackground from '../../resources/assets/images/bg.jpg';
import { themes as colorThemes } from './themes/styleAttributes/colors';

const LoadingSpinner = ({ isBetweenPages = false }) => {
  const isDarkMode = useSelector((state) => state.view.darkMode);
  const backgroundImage = isDarkMode ? darkBackground : lightBackground;
  const borderColor = colorThemes[isDarkMode ? 'dark' : 'light'].commonText;
  return (
    <div
      className="simple-flex"
      style={{
        height: '100vh',
        width: '100%',
        justifyContent: 'center',
        background: isBetweenPages ? `url(${backgroundImage})` : '',
      }}
    >
      <CSpinner
        style={{
          width: 50,
          height: 50,
          borderWidth: 10,
          borderLeftColor: borderColor,
          borderTopColor: borderColor,
          borderBottomColor: borderColor,
          borderRightColor: 'transparent',
        }}
      />
    </div>
  );
};

export default LoadingSpinner;
