import { useState, useEffect } from 'react';
import {
  useFloating,
  autoUpdate,
  offset as offsetMiddleware,
  flip,
  autoPlacement,
  shift,
  useClick,
  useHover,
  useDismiss,
  useInteractions,
  useTransitionStatus,
} from '@floating-ui/react';

import { useMousePosition } from '@/shared/hooks/use-mouse-position';

// Based on https://floating-ui.com/docs/useFloating
export function usePopup({
  placement = 'bottom-end',
  offset = 0,
  flipEnabled = true,
  autoPlacementEnabled = false,
  clickEnabled = true,
  dismissEnabled = true,
  hoverEnabled = false,
  transitioned = false,
  transitionDuration = 300,
  sticky = false,
} = {}) {
  // Base
  const [isOpen, setIsOpen] = useState(false);

  const middleware = [offsetMiddleware(offset), shift()];
  // Why? https://floating-ui.com/docs/autoPlacement#conflict-with-flip
  if (autoPlacementEnabled) {
    middleware.push(autoPlacement());
  } else if (flipEnabled) {
    middleware.push(flip());
  }

  const { refs, floatingStyles, context } = useFloating({
    placement: placement,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware,
    whileElementsMounted: autoUpdate,
  });

  // Interactions
  const click = useClick(context, {
    enabled: clickEnabled,
  });

  const dismiss = useDismiss(context, {
    enabled: dismissEnabled,
  });

  const hover = useHover(context, {
    enabled: hoverEnabled,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    hover,
  ]);

  // Transition
  const { isMounted, status: transitionStatus } = useTransitionStatus(context, {
    duration: transitionDuration,
  });

  // Sticky position
  const mousePosition = useMousePosition({ disabled: !isOpen });

  useEffect(() => {
    if (!sticky || !isOpen) {
      return;
    }

    const { x, y } = mousePosition;

    refs.setPositionReference({
      getBoundingClientRect() {
        return {
          width: 0,
          height: 0,
          x: x,
          y: y,
          top: y,
          left: x,
        };
      },
    });

  }, [mousePosition, sticky, isOpen, refs]);

  return {
    refs,
    floatingStyles,
    isOpen,
    setIsOpen,
    context,
    // use isMounted, when using transitioned: true. Otherwise use isOpen
    isMounted,
    transitionClass: transitioned ? `popup-transition popup-transition--${transitionStatus}` : '',
    getReferenceProps,
    getFloatingProps,
  };
}
