import { useState } from 'react';

import { useBackendErrors } from '@/shared/hooks/use-backend-errors';
import { PLAN_NAMES } from '@/shared/constants';
import {
  getPlans as apiGetPlans,
} from '../api';

export function useGetPlans() {
  const [fetchingState, setFetchingState] = useState(null);
  const [freePlan, setFreePlan] = useState(null);
  const [proPlan, setProPlan] = useState(null);
  const [enterprisePlan, setEnterprisePlan] = useState(null);

  const { errors, getErrors } = useBackendErrors();

  async function getPlans(quantity, stripe_subscription_id) {
    try {
      setFetchingState('loading');
      const response = await apiGetPlans({ quantity, stripe_subscription_id });
      setFreePlan(response.data.filter((el) => el.name === PLAN_NAMES.free)[0]);
      setProPlan(response.data.filter((el) => el.name === PLAN_NAMES.pro)[0]);
      setEnterprisePlan(response.data.filter((el) => el.name === PLAN_NAMES.enterprise)[0]);
      setFetchingState('success');
    } catch (err) {
      getErrors(err);
      console.error(err);
      setFetchingState(null);
    }
  }

  return {
    fetchingState,
    errors,
    getPlans,
    freePlan,
    proPlan,
    enterprisePlan,
  };
}
