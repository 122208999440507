import * as actionTypes from './actionTypes';
import axios from '../../util/axios-config';
import { handleBackendError, showToast } from '../../util/basic';
import { AuthClass } from '../../util/auth';
import { Lang } from '../../util/preferences';
import { setColorTheme, setDefaultLoc } from './view';
import { getStatus } from '../../util/permissions';
import useAnalyticsEventTracker from '../../util/hooks/useAnalyticsEventTracker';

// eslint-disable-next-line
const trackCreateLocEvent = useAnalyticsEventTracker('Create location');

// Actions

export const setCurrentUserEmail = (email) => ({
  type: actionTypes.currentUser.SET_EMAIL,
  email,
});

export const getCurrentUserInfoRequest = () => ({
  type: actionTypes.currentUser.GET_CURRENT_USER_INFO_REQUEST,
});

export const getCurrentUserInfoSuccess = ({
  data: {
    organizations,
    locations,
    units,
    email,
    first_name,
    last_name,
    is_verified,
    id,
    notifications_minimum_interval,
    new_email,
    alert_email_device_offline,
    alert_email_device_online,
    alert_email_parameter_change,
    alert_email_low_battery,
    api_keys,
    is_helpdesk_admin,
  },
}) => ({
  type: actionTypes.currentUser.GET_CURRENT_USER_INFO_SUCCESS,
  organizations,
  isVerified: is_verified,
  locations,
  units,
  email,
  first_name,
  last_name,
  new_email,
  alert_email_device_offline,
  alert_email_device_online,
  alert_email_parameter_change,
  alert_email_low_battery,
  id,
  api_keys,
  notifications_minimum_interval,
  is_helpdesk_admin,
});

export const createOrgSuccess = ({ data }) => ({
  type: actionTypes.currentUser.CREATE_ORG_SUCCESS,
  organization: data,
});

export const setCurrentOrganizationId = (id) => ({
  type: actionTypes.currentUser.SET_CURRENT_ORGANIZATION_ID,
  id,
});

export const createLocRequest = () => ({
  type: actionTypes.currentUser.CREATE_LOC_REQUEST,
});

export const createLocSuccess = ({ data }) => ({
  type: actionTypes.currentUser.CREATE_LOC_SUCCESS,
  location: data,
});

export const editLocRequest = () => ({
  type: actionTypes.currentUser.EDIT_LOC_REQUEST,
});

export const editLocSuccess = ({ data }) => ({
  type: actionTypes.currentUser.EDIT_LOC_SUCCESS,
  location: data,
});

export const deleteLocRequest = () => ({
  type: actionTypes.currentUser.DELETE_LOC_REQUEST,
});

export const deleteLocSuccess = (locId) => ({
  type: actionTypes.currentUser.DELETE_LOC_SUCCESS,
  locationId: locId,
});

export const editOrgRequest = () => ({
  type: actionTypes.currentUser.EDIT_ORG_REQUEST,
});

export const editOrgSuccess = ({ data }) => ({
  type: actionTypes.currentUser.EDIT_ORG_SUCCESS,
  organization: data,
});

export const editUserRequest = () => ({
  type: actionTypes.currentUser.EDIT_USER_REQUEST,
});

export const editUserSuccess = ({ data }) => ({
  type: actionTypes.currentUser.EDIT_USER_SUCCESS,
  newUserData: data,
});

export const updateOrganization = ({ data }) => ({
  type: actionTypes.currentUser.UPDATE_ORGANIZATION,
  organization: data,
});

export const updateCurrentUserInfo = (
  { locations, organizations, first_name, last_name },
  currentOrgId
) => ({
  type: actionTypes.currentUser.UPDATE_CURRENT_USER_INFO,
  locations,
  organization: organizations[0],
  first_name,
  last_name,
  currentOrgId,
});

export const setHasDevices = (hasDevices) => ({
  type: actionTypes.currentUser.SET_HAS_DEVICES,
  hasDevices: hasDevices,
});

export const setNewAPIKey = (api_key) => ({
  type: actionTypes.currentUser.SET_NEW_API_KEY,
  api_key: api_key,
});

export const nonAuthorizedLangChange = (lang) => ({
  type: actionTypes.currentUser.NON_AUTHORIZED_LANG_CHANGE,
  lang,
});

// Dispatchers

export const handleOrganiztionChange = (
  newOrgId,
  pathname,
  organizations,
  allLocations,
  navigate
) => {
  return (dispatch) => {
    const splittedPath = pathname.split('/');
    dispatch(setHasDevices(true));
    if (splittedPath.includes('edit') || splittedPath.includes('edit_floor')) {
      const locations = allLocations.filter((loc) => loc.organization === newOrgId);
      const newStatus = getStatus(organizations, newOrgId, locations);
      const isEditPage = ['edit', 'edit_floor'].includes(splittedPath[2]);
      switch (newStatus) {
        case 'viewer':
          navigate('/dashboard');
          break;
        case 'locAdmin':
          if (splittedPath[1] === 'settings') {
            navigate('/dashboard');
          } else if (isEditPage) {
            navigate(`/${splittedPath[1]}`);
          }
          break;
        case 'orgAdmin':
          if (isEditPage) {
            navigate(`/${splittedPath[1]}`);
          }
          break;
        default:
          break;
      }
    }
    dispatch(setCurrentOrganizationId(newOrgId));
  };
};

export const getCurrentUserInfo = (t, i18n, nonAuthorizedLang) => {
  return (dispatch) => {
    dispatch(getCurrentUserInfoRequest());

    const axiosRequest = nonAuthorizedLang
      ? () =>
          axios.patch(
            'auth/users/me/',
            { language: nonAuthorizedLang },
            { headers: AuthClass.authHeaders() }
          )
      : () => axios.get('auth/users/me/', { headers: AuthClass.authHeaders() });

    axiosRequest()
      .then((response) => {
        dispatch(getCurrentUserInfoSuccess(response));
        const prefLang = response.data.language;
        if (prefLang) {
          i18n.changeLanguage(prefLang);
          Lang.set(prefLang);
        }
        dispatch(setColorTheme(response.data.mode === 'dark'));
        dispatch(nonAuthorizedLangChange(null));
      })
      .catch((e) => handleBackendError(t, dispatch, e));
  };
};

export const createLoc = (
  t,
  name,
  country,
  city,
  address,
  zip_code,
  timezone,
  organization,
  isFirstCreation
) => {
  return (dispatch) => {
    dispatch(createLocRequest());
    const data = { name, country, city, address, zip_code, timezone };
    return axios
      .post(`organizations/${organization}/locations/`, data, { headers: AuthClass.authHeaders() })
      .then((response) => {
        dispatch(createLocSuccess(response));
        if (isFirstCreation) {
          dispatch(setDefaultLoc(response.data));
        }
        localStorage.setItem('locationsLocation', response.data.id);
        trackCreateLocEvent('create_location');
      })
      .catch((e) => {
        throw e;
      });
  };
};

export const editOrg = (t, name, logo, webhookUrl, orgId, updatesCounter) => {
  return (dispatch) => {
    dispatch(editOrgRequest());
    const formData = new FormData();
    formData.append('name', name);
    formData.append('webhook_url', webhookUrl);
    if (updatesCounter > 0) {
      const logoFile = logo
        ? new File([logo], logo.name, { type: logo.type, lastModified: Date.now() })
        : '';
      formData.append('logo', logoFile);
    }

    axios
      .patch(`organizations/${orgId}/`, formData, {
        headers: AuthClass.authHeaders({ 'content-type': 'multipart/form-data' }),
      })
      .then((response) => dispatch(editOrgSuccess(response)))
      .then(() => showToast(dispatch, t('general:confirmation'), t('toasts:orgSaved')))
      .catch((e) => handleBackendError(t, dispatch, e));
  };
};

export const editUser = (
  t,
  first_name,
  last_name,
  units,
  alert_email_device_offline,
  alert_email_device_online,
  alert_email_parameter_change,
  alert_email_low_battery,
  new_email,
  validatorRef,
  cancel = false,
  notificationMinInterval
) => {
  return (dispatch) => {
    dispatch(editUserRequest());
    const data = {
      first_name,
      last_name,
      units,
      alert_email_device_offline,
      alert_email_device_online,
      alert_email_parameter_change,
      alert_email_low_battery,
      new_email,
      notifications_minimum_interval: notificationMinInterval,
    };
    axios
      .patch('auth/users/me/', data, { headers: AuthClass.authHeaders() })
      .then((response) => dispatch(editUserSuccess(response)))
      .then(() => {
        if (!cancel) {
          showToast(dispatch, t('general:confirmation'), t('toasts:profileSaved'));
        } else {
          validatorRef.current.clearFields(['firstName', 'lastName']);
        }
      })
      .catch((e) => handleBackendError(t, dispatch, e, ['new_email'], validatorRef));
  };
};

export const langChange = (lang, isAuthorized, i18n) => {
  return (dispatch) => {
    i18n.changeLanguage(lang);
    Lang.set(lang);
    if (isAuthorized) {
      axios.patch('auth/users/me/', { language: lang }, { headers: AuthClass.authHeaders() });
    } else {
      dispatch(nonAuthorizedLangChange(lang));
    }
  };
};
