import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

export const StyledTitle = styled.h2`
  font-size: 4.5rem !important;
  font-weight: 300;
  line-height: 1.2;
  margin-right: 1.5rem !important;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    font-size: calc(1.575rem + 3.9vw) !important;
  }
`;

function Error404() {
  const location = useLocation();
  const resource = location.state?.resource || 'page';

  return (
    <div className="error-page-layout">
      <div className="aligner">
        <div className="grouper">
          <StyledTitle>404</StyledTitle>
          <div>
            <h4>Oops! You{"'"}re lost.</h4>
            <p>The {resource} you are looking for was not found.</p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Error404;
