import React from 'react';
import cn from 'classnames';
import styles from './BaseContainerCard.module.css';

const BaseContainerCard = ({ className, children, isBordered, bgColor = 'primary', noShadow = false }) => {
  const classnames = [
    styles.root,
    className,
    isBordered && styles.border,
    noShadow && styles.noShadow,
    styles[`bgColor--${bgColor}`]
  ];

  return (
    <div className={cn(classnames)}>
      {children}
    </div>
  );
};

export default BaseContainerCard;
