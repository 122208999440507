import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export function useSidebar(isVisibleInitial = true) {
  const breakpoint = useSelector((state) => state.view.breakpoint);

  const [isVisible, setIsVisible] = useState(isVisibleInitial);
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    setIsVisible(isVisibleInitial);
  }, [isVisibleInitial]);

  useEffect(() => {
    if (breakpoint === 'small' || breakpoint === 'medium') {
      if (isVisible) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    }
  }, [isVisible, breakpoint]);

  function toggleSidebar() {
    setIsVisible(!isVisible);
  }

  function toggleMinimization() {
    setIsMinimized(!isMinimized);
  }

  return {
    isVisible,
    isMinimized,
    toggleSidebar,
    toggleMinimization,
  }
}
