import axios from '@/util/axios-config';
import { AuthClass } from '@/util/auth';

export async function getPlans() {
  const response = await axios
    .get(`/payments/plan/`, {
      headers: AuthClass.authHeaders(),
    })
    .catch((e) => {
      throw e;
    });
  return response;
}

export async function createPortalSession({ stripe_customer_id }) {
  const response = await axios
    .post(
      `/payments/create_portal_session/`,
      {
        stripe_customer_id,
      },
      { headers: AuthClass.authHeaders() }
    )
    .catch((e) => {
      throw e;
    });

  return response;
}

export async function addActivationCode({ code, organization }) {
  const response = await axios
    .post(
      `/payments/add_activation_code/`,
      {
        code,
        organization,
      },
      { headers: AuthClass.authHeaders() }
    )
    .catch((e) => {
      throw e;
    });

  return response;
}

export async function propagateSubscription(subscriptionData) {
  const response = await axios
    .get(`/payments/propagate_subscription_info/`, {
      headers: AuthClass.authHeaders(),
      params: subscriptionData,
    })
    .catch((e) => {
      throw e;
    });

  return response;
}

export async function modifySubscription(subscriptionData) {
  const response = await axios
    .post(
      `/payments/modify_subscription/`,
      subscriptionData,
      { headers: AuthClass.authHeaders() }
    )
    .catch((e) => {
      throw e;
    });
  return response;
}

export async function activateSubscription(subscriptionData) {
  const response = await axios
    .post(
      `/payments/activate_subscription/`,
      subscriptionData,
      { headers: AuthClass.authHeaders() }
    )
    .catch((e) => {
      throw e;
    });
  return response;
}
