/**
 * @deprecated Use checkIsOrgAdmin from @/processes/use-permissions.js
 */
export function isOrgAdmin(organizations, currentOrganizationId) {
  return organizations.find((org) => org.id === currentOrganizationId)?.role === 'admin';
}

/**
 * @deprecated Use checkIsLocAdmin from @/processes/use-permissions.js
 */
export function isLocAdmin(locations) {
  return locations.find((loc) => loc.role === 'admin');
}

/**
 * @deprecated Use hasAdminRights from @/processes/use-permissions.js
 */
export function isAdmin(organizations, currentOrganizationId, locations) {
  return isOrgAdmin(organizations, currentOrganizationId) || isLocAdmin(locations);
}

// if current org id is passed, tells if the user is an owner for current organization specifically
// else tells if the user is generally an owner of some organization
export function isOwner(organizations, userId, currentOrganizationId) {
  if (currentOrganizationId != null) {
    return organizations.find((org) => org.id === currentOrganizationId)?.owner === userId;
  }

  return organizations.find((org) => org.owner === userId);
}

export function getStatus(organizations, currentOrganizationId, locations) {
  if (isOrgAdmin(organizations, currentOrganizationId)) {
    return 'orgAdmin';
  }

  if (isLocAdmin(locations)) {
    return 'locAdmin';
  }

  return 'viewer';
}

export function isNotPassedStatus(organizations, currentOrganizationId, locations, status) {
  return getStatus(organizations, currentOrganizationId, locations) !== status;
}
