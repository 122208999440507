import { useRouteError } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Error404 from './Error404';
import Error500 from './Error500';

import styles from './ErrorRoot.module.css';

const definedErrorPages = {
  error404: <Error404 />,
  error500: <Error500 />,
};

export default function ErrorRoot() {
  const routeError = useRouteError();
  const { status, statusText, error } = routeError || {};

  const { t } = useTranslation(['errors']);

  const page = definedErrorPages[`error${status}`];

  if (!page) {
    return (
      <div className={styles.root}>
        <h1>{t('errors:unknownError')}</h1>
        <p>{statusText || error?.message}</p>
      </div>
    );
  }

  return page;
}
