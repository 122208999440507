import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatDateLocalized } from '@/shared/utils/formatDateLocalized';

import styles from './PromoCodeTable.module.css';
import { BaseButton } from '@/shared/ui/button';
import { getCodeStatus } from '@/shared/helpers';

export const PromoCodeTable = ({ promoCode, onClickCta }) => {
  const { i18n, t } = useTranslation(['plans']);
  const currentLanguage = i18n.language;

  return (
    <>
      {promoCode && (
        <table className={styles.root}>
          <thead>
            <tr>
              <th>{t('plans:date')}</th>
              <th>{t('plans:key')}</th>
              <th>{t('plans:status')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {promoCode.promo_date
                  ? formatDateLocalized(
                      new Date(promoCode.promo_date),
                      'LLL dd, yyyy',
                      currentLanguage
                    )
                  : '-'}
              </td>
              <td>{promoCode.code}</td>
              <td>
                {promoCode.status === 'p' ? (
                  <BaseButton variant="link" onClick={onClickCta}>
                    {t(`plans:${getCodeStatus(promoCode.status)}`)}
                  </BaseButton>
                ) : (
                  t(`plans:${getCodeStatus(promoCode.status)}`)
                )}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};
