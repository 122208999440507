export class Lang {
  static set(lang) {
    localStorage.setItem('lang', lang);
  }

  static get() {
    return localStorage.getItem('lang');
  }
}

export class CurrentOrgId {
  static set(id) {
    localStorage.setItem('currentOrg', id);
  }

  static get() {
    return +localStorage.getItem('currentOrg');
  }
}
